<template>
  <div>
    <div v-if="isMobile">
      <MobileHome></MobileHome>
    </div>
    <div v-else class="overflow-x-auto  w-full">
      <!--banner视频 -->
      <div class="relative text-white font-serif" @mousemove="handleMouseMove" @mouseleave="handleMouseLeave"
        :style="{ height: videoSuccess ? 'auto' : defaultVideoHeight + 'px' }">
        <!-- <div class="relative"> -->
        <div class="w-full h-full skeleton absolute" :class="videoSuccess ? ' hidden' : ' block'">
          <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <div class="relative">
              <img src="@/assets/images/index/loading.png" alt="loading" width="120" height="120"
                class="animate-spin" />
              <img src="@/assets/images/index/play.svg" alt="loading"
                class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
            </div>
          </div>
        </div>
        <video :class="videoSuccess ? '' : ' absolute'" @playing="onLoadedMetadata" preload="auto" loop muted
          ref="videoRef" @load="onVideoLoad" @loadedmetadata="onVideoLoad" @loadeddata="onVideoLoad"
          @error="onVideoError" src="/assets/images/home/banner.mp4"
          :style="{ width: '100%', display: 'block', inset: 0 }">
        </video>
        <div class="absolute bottom-16 w-full flex flex-col items-center justify-center ">
          <NuxtLink @click="onVideoBtn" :to="localePath({ name: 'master_center' })">
            <div ref="buttonRef"
              class="flex mb-[40px] w-64 h-14 rounded-full text-xl justify-center items-center bg-gradient">
              <div style="color: white;  background-clip: text;font-family: Roboto-Bold;font-weight: bold;"
                class="text-base flex items-center justify-center">
                {{ $t("dialog-free-subscription") }}
                <NuxtImg class="ml-4 w-[20px] h-[20px] arrow-move" src="/assets/images/index/home/arrow.png"
                  :alt="$t('AI_Enhance')" width="20" height="20" />
              </div>
            </div>
          </NuxtLink>
          <h1 class="font-Montserrat 2xl:text-6xl text-4xl text-center text-float max-w-[80%] m-0 2xl:mb-5 mb-2">
            {{ $t("index-desc1") }}
          </h1>
          <h2 class="Roboto-16 text-center text-float max-w-[80%]">
            {{ $t("index-desc3") }}
          </h2>
        </div>
      </div>
      <!-- 轮播商标 -->
      <div>
        <IndexMovingFigures></IndexMovingFigures>
      </div>
      <div class="relative flex text-center flex-col items-center">
        <!-- 视频--按钮切换 -->
        <IndexTabEnhanceBtn></IndexTabEnhanceBtn>
        <!-- AI Removal + AI Enhance + AI Generation -->
        <IndexRecommendationTool></IndexRecommendationTool>
        <!-- Expore PicMa Apps -->
        <IndexExporeApps></IndexExporeApps>
        <!-- We strive to use AI to assist people  -->
        <div class="relative mt-56">
          <div class="w-full items-center mb-10">
            <div class="Roboto-Bold-48">
              <p class="w-full mx-auto text-center font-Montserrat">
                {{ $t("index-review1") }}<br />
                <span class="text-button">{{ $t("index-review2") }}</span>
              </p>
              <div
                class="mx-auto my-[60px] flex gap-x-[30px] gap-y-[20px] flex-wrap justify-center w-4/5 lg:gap-x-[66px] lg:gap-y-[30px]">
                <NuxtImg src="/assets/images/home/Group76.webp" width="508" height="106" :alt="$t('AI_Enhance')"
                  loading="lazy" />
              </div>
              <IndexEvaluateStar :score="4.7" :starNum="5" :showScore="true" :halfStar="true"></IndexEvaluateStar>
              <div class="Roboto-16 mt-2 text-gray-500">{{ $t("Index-businesses") }}</div>
            </div>
          </div>
          <IndexReview></IndexReview>
          <div class="absolute left-0 -bottom-8 h-3/5 w-full bg-evaluate"></div>
        </div>
        <section style="z-index: 5;"
          class="w-full pt-[100px] pb-[100px] mx-auto lg:w-[900px] px-4 lg:px-0 lg:pt-[165px] lg:pb-[200px]">
          <p class="font-Montserrat text-5xl text-center">
            {{ $t("help-frequently") }}
          </p>
          <SupportCollapse :list="matching"></SupportCollapse>
        </section>
        <section style="z-index:5" class="h-[223px]" v-if="videoMetadataLoaded">
          <div class="grid place-items-center ">
            <p class="font-Montserrat font-bold text-center text-[30px] text-text-deep mb-10 lg:text-[32px]">
              {{ $t("help-contact-desc") }}
            </p>
            <gradientsBtn :to="`mailto:${email}`" @click="onEvent" :text="$t('help-contact')"
              style="font-size: 20px; font-weight: 700;width: fit-content;">
              <template #prefix>
                <div class="h-[64px] grid place-items-center">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M19.0356 20.4561C21.4581 18.4384 23 15.3992 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23H20.1528C20.3638 23 20.4636 22.6592 20.3013 22.5244C19.8003 22.1082 19.188 21.4187 19.0356 20.4561ZM10 11C10 12.1046 9.32843 13 8.5 13C7.67157 13 7 12.1046 7 11C7 9.89543 7.67157 9 8.5 9C9.32843 9 10 9.89543 10 11ZM14.5 13C15.3284 13 16 12.1046 16 11C16 9.89543 15.3284 9 14.5 9C13.6716 9 13 9.89543 13 11C13 12.1046 13.6716 13 14.5 13Z"
                      fill="white" />
                  </svg>
                </div>
              </template>
            </gradientsBtn>
          </div>
        </section>
        <NuxtImg style="z-index: 0;" class="w-full absolute bottom-0 left-0"
          src="/assets/images/index/home/BottomBG.webp" :alt="$t('AI_Enhance')" loading="lazy" />
      </div>
      <ClientOnly>
        <IndexGuideLogin></IndexGuideLogin>
      </ClientOnly>
    </div>
  </div>

</template>
<script setup lang="ts">
import { email } from "@/utils/config";
import { useMainStore } from "@/store/index";
const { t } = useI18n();
const localePath = useLocalePath();
const mainStore = useMainStore();
const device = useDevice();
const link = useSeoLangLink()
const defaultVideoHeight = ref(952.5)
const { isLogin } = useUserInfo();
if (process.client) {
  resizeVideoHeight()
  console.log('客户端', defaultVideoHeight.value);
}
useHead({
  title: t("index-seo-title"),
  meta: [
    {
      name: "description",
      content: t("index-seo-desc"),
    },
    // {
    //   name: "keywords",
    //   content: t("index-seo-keywords"),
    // },
    // {
    //   property: "og:title",
    //   content: t("index-seo-title"),
    // },
    // {
    //   property: "og:description",
    //   content: t("index-seo-desc"),
    // },
    // {
    //   property: "og:url",
    //   content: locale == "en" ? HOST : HOST + "/" + locale.value,
    // },
    // {
    //   property: "og:image",
    //   content: 'assets/images/share.webp',
    // },
  ],
  link: [...link, { rel: 'prefetch', href: '/assets/images/home/banner.mp4', as: "video", type: "video/mp4" }]
})
const matching = ref([
  { title: t("help-payments-q-1"), content: t("help-payments-a-1") },
  { title: t("help-payments-q-2"), content: t("help-payments-a-2") },
  { title: t("help-guide-q-1"), content: t("help-guide-a-1") },
  { title: t("help-guide-q-2"), content: t("help-guide-a-2") },
  { title: t("help-privacy-q-2"), content: t("help-privacy-a-2") },
]);

const isMobile = computed(() => {
  return device.isMobile || mainStore.isMobile
})

//#region 跟随鼠标移动的button
const videoRef = ref<HTMLVideoElement>();
const buttonRef = ref<HTMLDivElement>();
const buttonVisible = ref(false);
const buttonPosition = ref({ x: 0, y: 0 });
// 计算按钮样式
const buttonStyle = computed(() => {
  // if (!buttonVisible.value) {
  //   return 'display: none;';
  // }
  const button = buttonRef.value;
  if (!button) return '';
  // const rect = videoRef.value.getBoundingClientRect();
  const x = buttonPosition.value.x - button.offsetWidth / 2;
  const y = buttonPosition.value.y - button.offsetHeight / 2;
  return `transform: translate3d(${x}px, ${y}px,0); display: block;`;
});
const videoSuccess = ref(false)
function onVideoLoad() {
  console.log('video load');

  videoSuccess.value = true
}
function onVideoError(error: any) {
  console.log('video error', error);
}
function handleMouseMove(event: MouseEvent) {
  const parentElement = event.currentTarget; // 获取当前事件目标（父元素）
  const rect = parentElement.getBoundingClientRect(); // 获取父元素的边界框

  const video = videoRef.value;
  // console.log('video.paused',video.paused,video.ended);

  if (video && video.paused && !video.ended) {
    const playPromise = video.play();
    if (playPromise !== undefined) {
      playPromise.then(() => {
        console.log('视频自动播放');
        // 视频开始播放
      }).catch(error => {
        console.warn('视频自动播放失败:', error);
      });
    }
  }
  buttonVisible.value = true;
  buttonPosition.value = { x: event.clientX - rect.left, y: event.clientY - rect.top };
  if (buttonPosition.value.y > defaultVideoHeight.value) {
    buttonVisible.value = false;
  }

}

function handleMouseLeave() {
  // const video = videoRef.value;
  // if (video) {
  //   const playPromise = video?.play();
  //   if (playPromise !== undefined) {
  //   playPromise.then(_ => {
  //     // Automatic playback started!
  //     // Show playing UI.
  //   })
  //   .catch(error => {
  //     // Auto-play was prevented
  //     // Show paused UI.
  //   });
  // }
  // }
  buttonVisible.value = false;
}

function handleMouseEnter() {
  const video = videoRef.value;
  if (video) {
    video?.play();
  }
}

function handleMouseEnterButton() {
  // 防止按钮悬停时视频暂停
}

function handleMouseLeaveButton() {
  // 防止按钮悬停时视频暂停
}
//#endregion

//#region 轮播商标
// 轮播数据（图片路径）
const images = [
  "/assets/images/home/1.png",
  "/assets/images/home/2.png",
  "/assets/images/home/3.png",
  "/assets/images/home/4.png",
  "/assets/images/home/5.png",
  "/assets/images/home/6.png",
  "/assets/images/home/7.png",
  "/assets/images/home/8.png",
];

// 复制图片列表，用于实现无缝循环
const duplicatedImages = computed(() => [...images, ...images]);

// 控制滑动的 translateX 值
const translateX = ref(0);
const sliderSpeed = ref(1); // 滑动速度（像素/帧）

// 动画控制
let animationFrameId = 0;

const animate = () => {
  translateX.value -= sliderSpeed.value;
  // 图片列表的总宽度
  const totalWidth = duplicatedImages.value.length * 200; // 每张图片宽度为 100px
  // 如果滑动到图片列表的一半，重置位置
  if (Math.abs(translateX.value) >= totalWidth) {
    translateX.value = 0;
  }
  animationFrameId = requestAnimationFrame(animate);
};


const videoMetadataLoaded = ref(false);
const onLoadedMetadata = () => {
  videoMetadataLoaded.value = true;
}

onMounted(() => {
  animate(); // 启动动画

  videoMetadataLoaded.value = true;
  // if (videoRef.value) {
  //   videoRef.value.src = '/assets/images/home/banner.mp4'
  // }
  if (!isLogin.value && !mainStore.isMobile && !localStorage.getItem('guideDialog')) {
    mainStore.setVisibleGuide(true)
  }
  window.addEventListener('resize', resizeVideoHeight)
});

onUnmounted(() => {
  cancelAnimationFrame(animationFrameId); // 清除动画帧
  window.removeEventListener('resize', resizeVideoHeight)
});
function resizeVideoHeight() {
  defaultVideoHeight.value = document.documentElement.clientWidth / 1920 * 960;
}
function onVideoBtn() {
  useLogEvent({ eventName: 'index-video-btn' });
}

function onEvent() {
  useLogEvent({ eventName: 'index-contact-us' });
}
</script>
<style lang="scss" scoped>
.bg-evaluate {
  background-image: linear-gradient(180deg, #FFF6EE10 0%, #fff6ee 100%)
}

@keyframes bounce {

  0%,
  100% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1);
  }
}

/* 应用动画到图标 */
.icon-animation {
  transition: all 0.1s ease-in-out;
  color: #c02cff;
}

/* 鼠标悬停时触发动画 */
.icon-animation:hover {
  animation: bounce 10s infinite;
  /* 动画持续1秒，无限循环 */
}

.text-button {
  background-image: linear-gradient(to right, #4a2dff, #ca4dff);
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

/* 按钮样式 */
button {
  opacity: 0.9;
  /* 按钮透明度 */
  transition: opacity 0.3s ease;
  /* 平滑过渡 */
}

/* 按钮悬停时效果 */
button:hover {
  opacity: 1;
  /* 按钮完全不透明 */
}

.background-tool {
  background: linear-gradient(to bottom, #e7e9ff00, #d2d6ff);
}

/* 添加以下样式以隐藏文本在 hover 时 */
.group-hover .text-2xl,
.group-hover .text-sm {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.group:hover .text-2xl,
.group:hover .text-sm {
  opacity: 1;
}


.video-container {
  position: relative;
  width: 100%;
  // height: 90%;
}

.button {
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.1s;
  display: none;
}

.text-float {
  text-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

/* 自定义旋转动画 */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 4s linear infinite;
  /* 2秒一圈，线性，无限循环 */
}

.skeleton {
  background-image: url('/assets/images/home/banner.webp');
  /* 设置背景图片 */
  background-size: cover;
  /* 让图片覆盖整个区域 */
  background-position: center;
  /* 图片居中显示 */
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  position: relative;
  /* 确保子元素的定位正确 */
}

/* 如果需要在背景图片上叠加渐变效果，可以使用伪元素 */
.skeleton::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgb(210 207 207) 25%, rgb(158 153 153) 37%, rgb(197 196 196) 63%);
  opacity: 0.5;
  /* 调整透明度以控制渐变效果的强度 */
  animation: gradient 2s cubic-bezier(0, 0, 1, 1) infinite;
  background-size: 400% 100%;
}

@keyframes gradient {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

// .lrmove {
//   animation: move 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
// }
// @keyframes move {
//   0% {
//     transform: translate(0px,0px,0px);
//   }
//   50% {
//     transform: translate(12px,0px,0px);
//   }
//   100% {
//     transform: translate(0px,0px,0px);
//   }
// }</style>
